// Login. Universal to individuals / teachers / admins

import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';
import { Formik } from 'formik';

// Antd Components
import {
    Typography as AntTypography,
    Input,
    Button as AntButton,
    Modal,
    Row,
    Col,
    Form,
    Card,
    notification,
} from 'antd';

// AntD Icons
import { LoadingOutlined } from '@ant-design/icons';

import { TitleProps } from 'antd/lib/typography/Title';
import { TextProps } from 'antd/lib/typography/Text';

import styled from 'styled-components';

import { validatePhone, validatePassword } from '../utils/validate';
import user from '../services/user';

import { setCookie } from '../utils/browser';
import { getToken } from '../services/_config';

const { Text, Title } = AntTypography;

const PageTitle = styled(Title)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
        font-weight: 500;
        margin-bottom: 15px;
        text-align: center;
    }
`;

const TextBlock = styled(Text)<TextProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
    }
`;

type TFormInputs = {
    phone: string;
    password: string;
};

type TErrors = {
    phone: string | true;
    password: string | true;
};

const Login = (): JSX.Element => {
    const [success, setSuccess] = useState<boolean>(false);

    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = (): void => {
        setOpen1(true);
    };

    const [resetData, setResetData] = useState({ phone: '', code: '', password: '' });

    const handleClose1 = (): void => {
        setResetData({ phone: '', code: '', password: '' });
        setOpen1(false);
    };

    const [open2, setOpen2] = React.useState(false);

    const handleClose2 = (): void => {
        setResetData({ phone: '', code: '', password: '' });
        setOpen2(false);
    };

    const { push, query } = useRouter();

    const showError = (message: string | null = ''): void => {
        notification.error({
            message,
        });
    };

    const formInputs: TFormInputs = {
        phone: '',
        password: '',
    };

    const onValidate = (values: TFormInputs): TErrors => {
        const errors = {} as TErrors;

        // --- Validate Phone
        const phoneValidation = validatePhone(values.phone);
        if (phoneValidation !== true) {
            errors.phone = phoneValidation;
        }

        // --- Validate Password
        const passwordValidation = validatePassword(values.password);
        if (passwordValidation !== true) {
            errors.password = passwordValidation;
        }

        return errors;
    };

    const goHome = (): void => {
        if (query.redirect) {
            push(`/${query.redirect}`);
            return;
        }
        push('/');
    };

    const onSubmit = async (
        values: TFormInputs,
        { setSubmitting }: { setSubmitting: (x: boolean) => void },
    ): Promise<void> => {
        setSubmitting(true);

        try {
            const { data } = await user.login({ phone: values.phone, password: values.password });

            setCookie('token', data.token);

            setTimeout(async () => {
                try {
                    await user.get();

                    setSuccess(true);
                    setTimeout(async () => {
                        let token = await getToken();
                        while (!token) {
                            // eslint-disable-next-line no-await-in-loop
                            token = await getToken();
                        }
                        setSubmitting(false);
                        goHome();
                    }, 2000);
                } catch (err) {
                    Sentry.captureException(err);
                }
            }, 500);
        } catch (err) {
            Sentry.captureException(err);
            setSubmitting(false);
            showError(err.message);
        }
    };

    return (
        <div
            style={{
                width: '100vw',
                maxWidth: 'unset',
                background: 'url(https://slomf.s3-ap-southeast-1.amazonaws.com/assets/auth-bg.jpg) no-repeat center',
                backgroundSize: 'cover',
            }}>
            <Row
                style={{
                    height: '100vh',
                }}
                align="middle"
                justify="center">
                <Col xs={20} sm={18} md={15} lg={12} xl={10} xxl={8}>
                    <Card>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                            <img src="/images/logo-color.svg" height={40} alt="SLMOF" />
                        </div>
                        <PageTitle level={3} style={{ marginBottom: 15 }}>
                            Sign In
                        </PageTitle>

                        {/* <PageTitle level={5} style={{ textAlign: 'center', fontSize: 12, color: '#575757' }}>
                            This is needed to register yourself or a student to competitions.
                        </PageTitle> */}

                        <Formik initialValues={formInputs} validate={onValidate} onSubmit={onSubmit}>
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }): JSX.Element => (
                                <>
                                    <Form.Item
                                        label={<TextBlock>Phone</TextBlock>}
                                        labelCol={{ span: 24 }}
                                        colon={false}
                                        labelAlign="left"
                                        help={!!errors.phone && touched.phone && errors.phone}
                                        validateStatus={!!errors.phone && touched.phone ? 'error' : ''}>
                                        <Input
                                            id="phone"
                                            value={values.phone}
                                            placeholder="Phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ width: '100%' }}
                                            name="phone"
                                            type="phone"
                                            onKeyPress={(ev: React.KeyboardEvent): void => {
                                                if (ev.key === 'Enter') {
                                                    handleSubmit();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={<TextBlock>Password</TextBlock>}
                                        labelCol={{ span: 24 }}
                                        colon={false}
                                        labelAlign="left"
                                        help={!!errors.password && touched.password && errors.password}
                                        validateStatus={!!errors.password && touched.password ? 'error' : ''}>
                                        <Input.Password
                                            id="password"
                                            value={values.password}
                                            placeholder="Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ width: '100%' }}
                                            name="password"
                                            type="password"
                                            onKeyPress={(ev: React.KeyboardEvent): void => {
                                                if (ev.key === 'Enter') {
                                                    handleSubmit();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <AntButton
                                        type={success ? 'default' : 'primary'}
                                        style={{ width: '100%' }}
                                        onClick={handleSubmit as () => void}>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {isSubmitting ? <LoadingOutlined /> : success ? 'Success!' : 'Login'}
                                    </AntButton>
                                </>
                            )}
                        </Formik>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Row justify="center">
                                <AntButton
                                    type="text"
                                    onClick={(): void => {
                                        push('/register');
                                    }}>
                                    <TextBlock
                                        style={{
                                            fontSize: '12px',
                                        }}>
                                        Create an account
                                    </TextBlock>
                                </AntButton>
                            </Row>

                            <TextBlock style={{ margin: 5 }}>|</TextBlock>

                            <Row justify="center">
                                <AntButton type="text" onClick={handleClickOpen1}>
                                    <TextBlock
                                        style={{
                                            fontSize: '12px',
                                        }}>
                                        Reset Password
                                    </TextBlock>
                                </AntButton>
                            </Row>
                        </Row>
                    </Card>

                    <Card>
                        <p>
                            <a href="/register">Create a user account</a> first to register for competitions
                        </p>
                        <p>
                            තරඟ සඳහා ලියාපදිංචි වීමට, පළමුව <a href="/register">පරිශීලක ගිණුමක් සාදන්න</a>
                        </p>
                        <p>
                            போட்டிகளில் பங்குபற்றுவதற்கு முன்னர் உங்களது{' '}
                            <a href="/register">தனிப்பட்ட கணக்கினை உருவாக்கிக்</a> கொள்ளவும்
                        </p>
                    </Card>
                    <Modal
                        visible={open1}
                        centered
                        aria-labelledby="form-dialog-title"
                        title="Reset password"
                        okText="Submit"
                        onOk={async (): Promise<void> => {
                            try {
                                await user.getResetCode(resetData.phone);
                                setOpen1(false);
                                setOpen2(true);
                            } catch (e) {
                                notification.error({
                                    message: e.message || 'Something went wrong',
                                    description: 'Please try again, or reach out to tech',
                                });
                            }
                        }}
                        onCancel={handleClose1}>
                        <TextBlock style={{ fontWeight: 500, marginBottom: '20px' }}>
                            Please enter the phone number you used when registering
                        </TextBlock>
                        <br />
                        <br />
                        <Form.Item
                            label={<TextBlock>Phone number</TextBlock>}
                            labelCol={{ span: 24 }}
                            colon={false}
                            labelAlign="left">
                            <Input
                                autoFocus
                                id="phone"
                                value={resetData.phone}
                                placeholder="Phone number"
                                onChange={async (event): Promise<void> => {
                                    setResetData({ phone: event.target.value, code: '', password: '' });
                                }}
                                style={{ width: '100%' }}
                                type="phone"
                            />
                        </Form.Item>
                    </Modal>
                    <Modal
                        visible={open2}
                        centered
                        aria-labelledby="form-dialog-title"
                        title="Reset password"
                        okText="Submit"
                        onOk={async (): Promise<void> => {
                            try {
                                await user.setNewPassword(resetData);
                                setOpen2(false);
                                notification.success({
                                    message: 'Password changed',
                                    description: 'Please login with new password',
                                });
                            } catch (e) {
                                notification.error({
                                    message: e.message,
                                    description: 'Please try again',
                                });
                            }
                        }}
                        onCancel={handleClose2}>
                        <TextBlock style={{ fontWeight: 500 }}>
                            The provided phone number will receive a text message with a verification code
                        </TextBlock>
                        <TextBlock style={{ fontWeight: 500 }}>Please enter it and your new password below</TextBlock>
                        <br />
                        <br />
                        <Form.Item
                            label={<TextBlock>Verification code</TextBlock>}
                            labelCol={{ span: 24 }}
                            colon={false}
                            labelAlign="left">
                            <Input
                                autoFocus
                                id="code"
                                value={resetData.code}
                                placeholder="Phone number"
                                onChange={async (event): Promise<void> => {
                                    setResetData({ ...resetData, code: event.target.value });
                                }}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<TextBlock>New password</TextBlock>}
                            labelCol={{ span: 24 }}
                            colon={false}
                            labelAlign="left">
                            <Input
                                autoFocus
                                id="password"
                                value={resetData.password}
                                placeholder="New password"
                                onChange={async (event): Promise<void> => {
                                    setResetData({ ...resetData, password: event.target.value });
                                }}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
};
export default Login;
